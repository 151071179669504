@import "../main/abstracts/index";

.section--top-topics-custom{

    header{

        h2{

            .subheadline{
                @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
                font-weight: $font__weight--normal;
                color: $color__black;
                margin-left: 35px;
            }

            @include media-breakpoint-down(md) {

                .subheadline{
                    margin-left: 0;
                    margin-top: 6px;
                }

                span{
                    display: block;
                }
            }
        }
    }
}

.top-topics{

    body:not([data-editmode="true"]) &__item:not(.top-topics__item--has-image) {
        color: $color__font;
        background-color: $color__gray--light;

        &.top-topics__item--has-link{

            @include hover-focus-visible{
                background-color: $color__gray--light!important;
            }

            @include hover-focus-within{
                background-color: $color__gray--light!important;
            }

            &:hover,
            &:focus {
                background-color: $color__gray--light!important;
            }

            .top-topics__no-image figure{
                max-height: 300px;
                width: 100%;
            }
        }
    }

    body:not([data-editmode="true"]) &__item:not(.top-topics__item--has-image).top-topics__item--size-big{

        .top-topics{

            &__no-image{
                height: 100%;
                min-height: 150px;

                figure{
                    height: 100%;
                    width: 100%;
                    aspect-ratio: 16/7;
                    max-height: 300px;
                }
            }

            &__caption{
                position: relative;
                width: 100%;
            }
        }
    }

    body:not([data-editmode="true"]) &__item:not(.top-topics__item--has-image){
        color: $color__font;
        background-color: $color__gray--light;

        *{
            color: $color__font;
        }
    }

    &__item {
        border: none;
        overflow: hidden;

        &--has-link {

            @include hover-focus-visible{

                a.stretched-link{
                    text-decoration: none!important;
                    filter: none;
                }

                .top-topics{

                    &__caption .icon-arrow{
                        background-color: $color__primary;

                        img{
                            filter: $filter__white!important;
                        }
                    }
                }
            }

            @include focus-within{

                a.stretched-link{
                    text-decoration: none!important;
                    filter: none;
                }

                .top-topics{

                    &__caption .icon-arrow{
                        background-color: $color__primary;

                        img{
                            filter: $filter__white!important;
                        }
                    }
                }
            }

            a.stretched-link{
                color: $color__font!important;

                @include hover-focus-visible{
                    filter: none;
                }

                &:hover,
                &:focus{
                    filter: none;
                }
            }

        }

        &--has-image{

            .top-topics__image{

                figure{
                    border: none;
                }
            }

            figure{
                aspect-ratio: 16/9;
                display: flex;
                align-items: center;
                justify-content: center;

                img:not(.icon){
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    -webkit-transform: scale(1);
                    transform: scale(1);
                    transition: transform .2s ease-in-out;
                }

                img.icon{
                    max-height: 170px;
                    height: 65%;
                    max-width: 70%;
                    //filter: $filter__primary;
                }
            }

            &.top-topics__item--has-link{

                @include hover-focus-visible{

                    figure img:not(.icon){
                        -webkit-transform: scale(1.07);
                        transform: scale(1.07);
                    }
                }

                &.focus-within{

                    figure img:not(.icon){
                        -webkit-transform: scale(1.07);
                        transform: scale(1.07);
                    }
                }
            }
        }

        &--color-blue{
            background-color: $color__primary-4;
        }

        &--color-red{
            background-color: $color__tertiary;
        }

        &--color-green{
            background-color: $color__secondary-2;
        }

        &--color-blue,
        &--color-red,
        &--color-green{
            >*{
                background-color: transparent!important;
            }
        }

        &--size-big{

            .top-topics{

                &__image{
                    height: 100%;
                    min-height: 150px;

                    figure{
                        height: 100%;
                        aspect-ratio: 16/7;
                        max-height: 500px;
                    }
                }

                &__caption{
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        width: 85%;
                        align-self: end;
                        margin-left: auto;
                        z-index: 1;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 75%;
                    }
                }
            }

            &.top-topics__item{
                @include media-breakpoint-up(md) {
                    display: grid;
                }

                >*{
                    @include media-breakpoint-up(md) {
                        grid-area: 1 / 1;
                    }
                }

                &--color-blue,
                &--color-red,
                &--color-green{

                    .top-topics{

                        &__image figure{
                            aspect-ratio: 16/9;
                            max-height: 300px;

                            @include media-breakpoint-up(md) {
                                aspect-ratio: 16/7;
                                max-height: auto;
                            }
                        }

                        &__caption{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__image{
        border: none;
        overflow: hidden;

        figure{
            border-bottom: 0.0625rem solid #959594;
            max-height: 300px;
            width: 100%;
        }
    }

    &__no-image{

        figure{
            aspect-ratio: 16/9;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                max-height: 170px;
                filter: invert(60%) sepia(5%) saturate(47%) hue-rotate(22deg) brightness(98%) contrast(86%);
            }
        }
    }

    &__caption{
        padding: 12px 60px 12px 14px!important;
        justify-content: flex-start!important;
        background-color: $color__gray--light;

        @include media-breakpoint-up(md) {
            padding: 12px 75px 12px 25px!important;
        }

        h3{
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 25px);
            margin-bottom: 0;
        }

        .icon-arrow{
            position: absolute;
            display: flex;
            justify-content: center!important;
            align-items: center;
            right: 12px;
            bottom: 15px;
            padding: 0!important;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            background-color: $color__white;

            @include media-breakpoint-up(md) {
                right: 22px;
            }

            img{
                height: 30px;
                width: 30px;
                filter: $filter__primary-2!important;
            }
        }
    }
}
